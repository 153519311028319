import React from 'react'
import '../styles/Drawer.css'

export default function Drawer({ drawerOpen }) {
    return (
        <>
            <div className={`drawer ${drawerOpen && 'drawer--open'}`}>
                <ul className="drawer__list">
                    <li className="drawer__nav-item">
                        <a href="/" className="drawer__nav-link">
                            Home
                        </a>
                    </li>
                    <li className="drawer__nav-item">
                        <a href="/about" className="drawer__nav-link">
                            About
                        </a>
                    </li>
                    <li className="drawer__nav-item">
                        <a href="/nailTechs" className="drawer__nav-link">
                            Nail Techs
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}
