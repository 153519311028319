import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useMediaQuery } from 'react-responsive'
import { useParallax } from 'react-scroll-parallax'
import ScrollAnimation from 'react-animate-on-scroll'
import LoadingScreen from '../components/LoadingScreen'
import CrossfadeImage from 'react-crossfade-image'
import '../styles/Home.css'

export default function Home() {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadedImages, setLoadedImages] = useState(0)
    const [mainImage, setMainImage] = useState(0)

    const totalImages = 4
    let isFirstRound = true

    const isTablet = useMediaQuery({ query: '(max-width: 964px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 822px)' })

    const parallaxRefs = useRef([])

    let imagePaths = ['splash_background.png', 'splash_background2.png', 'splash_background3.png']

    const imageElements = document.getElementsByClassName('splash__background-image')

    const handleParallax = () => {
        parallaxRefs.current.forEach((imgRef) => {
            if (imgRef) {
                const speed = 0.3
                const offset = window.scrollY * speed
                imgRef.style.transform = `translateY(${offset}px)`
            }
        })
    }

    function rotateImages() {
        const topImage = imageElements[0]
        topImage.classList.remove('top')
        imageElements[1].classList.add('top')
        imageElements[1].classList.remove('second')
        imageElements[2].classList.add('second')

        const parent = topImage.parentElement
        parent.appendChild(topImage) // Move top image to the bottom
    }

    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleParallax)

        //rotate the images every 4 seconds
        const timeout = setTimeout(() => {
            const interval = setInterval(() => {
                rotateImages()
            }, 4000)
            return () => clearInterval(interval)
        })
        return () => {
            window.removeEventListener('scroll', handleParallax)
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        imagePaths.forEach((img) => {
            const image = new Image()
            image.src = `/images/${img}`
            image.onload = () => {
                setLoading(false)
            }
        })
    }, [imagePaths])

    useEffect(() => {
        if (!isTablet && !isMobile) {
            setDrawerOpen(false)
        }
    }, [isTablet, isMobile])

    useEffect(() => {
        if (loadedImages === totalImages) {
            setLoading(false)
        }
    }, [loadedImages, totalImages])

    const handleImageLoad = () => {
        setLoadedImages((prev) => prev + 1)
    }

    return (
        <>
            {loading && <LoadingScreen />}
            <Navbar toggleDrawer={toggleDrawer} isHome={true} />
            <div
                className={`
                page-content 
                ${isTablet || isMobile ? 'mobile-block' : null} 
                ${drawerOpen ? 'drawer-open' : null}`}
            >
                <div>
                    <div
                        className={`splash ${isTablet || isMobile ? 'splash__background--mobile' : null}`}
                    >
                        <div className="splash__background-backdrop" />
                        {imagePaths.map((img, index) => (
                            <img
                                key={index}
                                ref={(el) => (parallaxRefs.current[index] = el)}
                                className={`
                                    ${index === 0 ? 'top' : index === 1 ? 'second' : ''}
                                    ${
                                        isTablet || isMobile
                                            ? 'splash__background-image '
                                            : 'splash__background-image'
                                    }`}
                                src={`/images/${img}`}
                                alt="Someone doing anothers nails"
                                onLoad={handleImageLoad}
                            />
                        ))}
                        <img
                            src="/logos/Logo_Large_White.svg"
                            alt="Blazzi logo"
                            className="splash__title"
                        />
                        <h2 className="splash__subtitle">
                            Finding the perfect nail tech for you has never been easier
                        </h2>
                    </div>
                    <div className="app-promotion">
                        <div className="app-promotion__box">
                            <p
                                className={`app-promotion__text home-text ${isMobile && 'app-promotion__text--mobile'}`}
                            >
                                Finding a nail tech can be stressful or intimidating and many people
                                don’t know where to start...
                            </p>
                            <p className="app-promotion__text--bold home-text--bold home-text--bold--mobile">
                                That’s where Blazzi steps in
                            </p>
                            <button
                                className={`app-promotion__button button-home ${isMobile && 'app-promotion__button--mobile'}`}
                            >
                                App coming soon
                            </button>
                        </div>
                    </div>
                    <div className="app-screens">
                        <div
                            className={`app-screens__container ${isMobile ? 'app-screens__container--mobile' : isTablet ? 'app-screens__container--table' : null}`}
                        >
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--mobile' : null}`}
                            >
                                <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={4}>
                                    {!isMobile ? (
                                        <ScrollAnimation
                                            animateIn="slideInLeft"
                                            animateOnce={true}
                                            duration={3}
                                        >
                                            <div
                                                className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                            >
                                                <img
                                                    className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                                    src="/images/search-filters.png"
                                                    alt="Blazzi app screen 1"
                                                    onLoad={handleImageLoad}
                                                />
                                                <p
                                                    className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                                >
                                                    Filter for your specific nail wants and needs
                                                </p>
                                            </div>
                                        </ScrollAnimation>
                                    ) : (
                                        <div
                                            className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                        >
                                            <img
                                                className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                                src="/images/search-filters.png"
                                                alt="Blazzi app screen 1"
                                                onLoad={handleImageLoad}
                                            />
                                            <p
                                                className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                            >
                                                Filter for your specific nail wants and needs
                                            </p>
                                        </div>
                                    )}
                                </ScrollAnimation>
                            </div>
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--dark-mobile' : null}`}
                            >
                                <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={3}>
                                    <div
                                        className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                    >
                                        <img
                                            className={`app-screens__screen-image--large ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                            src="/images/client-map.png"
                                            alt="Blazzi app screen 2"
                                            onLoad={handleImageLoad}
                                        />
                                        <p
                                            className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                        >
                                            Find individual nail artists local to you
                                        </p>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--mobile' : null}`}
                            >
                                <ScrollAnimation
                                    animateIn={'fadeIn'}
                                    animateOnce={true}
                                    duration={4}
                                >
                                    {!isMobile ? (
                                        <ScrollAnimation
                                            animateIn={!isMobile ? 'slideInRight' : ''}
                                            animateOnce={true}
                                            duration={3}
                                        >
                                            <div
                                                className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                            >
                                                <img
                                                    className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                                    src="/images/services.png"
                                                    alt="Blazzi app screen 3"
                                                    onLoad={handleImageLoad}
                                                />
                                                <p
                                                    className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                                >
                                                    See nail artist prices and policies before you
                                                    pay
                                                </p>
                                            </div>
                                        </ScrollAnimation>
                                    ) : (
                                        <div
                                            className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                        >
                                            <img
                                                className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                                src="/images/services.png"
                                                alt="Blazzi app screen 3"
                                                onLoad={handleImageLoad}
                                            />
                                            <p
                                                className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                            >
                                                See nail artist prices and policies before you pay
                                            </p>
                                        </div>
                                    )}
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
