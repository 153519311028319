import React, { useEffect, useRef } from 'react'
import ClipboardJS from 'clipboard'
import '../styles/Footer.css'

export default function Footer() {
    const emailRef = useRef(null)

    useEffect(() => {
        const clipboard = new ClipboardJS(emailRef.current, {
            text: () => emailRef.current.innerText,
        })
        return () => clipboard.destroy()
    }, [])

    const sendAlert = () => {
        alert('Email copied to clipboard!')
    }

    return (
        <div className="footer">
            <p className="footer__text">©2024 Blazzi</p>
            <p
                onClick={sendAlert}
                ref={emailRef}
                className="footer__text"
                style={{ cursor: 'pointer' }}
            >
                blazzibeauty@gmail.com
            </p>
        </div>
    )
}
