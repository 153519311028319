import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Flicking, { ViewportSlot } from '@egjs/react-flicking'
import { AutoPlay, Pagination } from '@egjs/flicking-plugins'
import { useMediaQuery } from 'react-responsive'
import LoadingScreen from '../components/LoadingScreen'
import ScrollAnimation from 'react-animate-on-scroll'
import '../styles/NailTechs.css'
import '../styles/Home.css'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/flicking-plugins.css'

export default function NailTechs() {
    const [desktopPlugins] = useState([
        new AutoPlay({ duration: 3000, direction: 'NEXT', stopOnHover: false }),
    ])
    const [mobilePlugins] = useState([
        new AutoPlay({ duration: 3000, direction: 'NEXT', stopOnHover: true }),
        new Pagination({ type: 'bullet', clickable: true }),
    ])
    const [loading, setLoading] = useState(true)
    const [loadedImages, setLoadedImages] = useState(0)

    const totalImages = 4

    const isMobile = useMediaQuery({ query: '(max-width: 822px)' })
    const isTablet = useMediaQuery({ query: '(max-width: 964px)' })

    useEffect(() => {
        if (loadedImages === totalImages) {
            setLoading(false)
        }
    }, [loadedImages, totalImages])

    const handleImageLoad = () => {
        setLoadedImages((prev) => prev + 1)
    }

    return (
        <>
            {loading && <LoadingScreen />}
            <Navbar isHome={false} />
            <div
                className={`
                page-content
                ${isTablet || isMobile ? 'mobile-block' : null}
                position-block `}
            >
                <div>
                    {isMobile && (
                        <div className="nail-techs--mobile">
                            <div>
                                <div className="nail-techs__banner">
                                    <h1 className="nail-techs__title--mobile">Nail Technicians</h1>
                                </div>
                                <Flicking
                                    className="nail-techs__flicking--mobile"
                                    circular={true}
                                    panelsPerView={-1}
                                    plugins={mobilePlugins}
                                    align="center"
                                >
                                    <div className="nail-techs__flicking-item--mobile">
                                        <div className="nail-techs__flicking-card--mobile">
                                            <p className="nail-techs__subtitle--mobile">
                                                Need to grow your clientele?
                                            </p>
                                        </div>
                                    </div>
                                    <div className="nail-techs__flicking-item--mobile">
                                        <div className="nail-techs__flicking-card--mobile">
                                            <p className="nail-techs__subtitle--mobile">
                                                Having trouble filling last minute cancelations?
                                            </p>
                                        </div>
                                    </div>
                                    <div className="nail-techs__flicking-item--mobile">
                                        <div className="nail-techs__flicking-card--mobile">
                                            <p className="nail-techs__subtitle--mobile">
                                                Want to optimize your business?
                                            </p>
                                        </div>
                                    </div>
                                    <ViewportSlot>
                                        <div className="flicking-pagination"></div>
                                    </ViewportSlot>
                                </Flicking>
                            </div>
                        </div>
                    )}

                    <div className={!isMobile && 'nail-techs'}>
                        <div
                            className={`nail-techs__info ${isMobile && 'nail-techs__info--mobile'}`}
                        >
                            {!isMobile && (
                                <div className="nail-techs__title-container">
                                    <h1 className="nail-techs__title">Nail Technicians</h1>
                                    <Flicking
                                        className="nail-techs__flicking"
                                        circular={true}
                                        panelsPerView={1}
                                        plugins={desktopPlugins}
                                        align="center"
                                    >
                                        <p className="nail-techs__subtitle">
                                            Need to grow your clientele?
                                        </p>
                                        <p className="nail-techs__subtitle">
                                            Having trouble filling last minute cancelations?
                                        </p>
                                        <p className="nail-techs__subtitle">
                                            Want to optimize your business?
                                        </p>
                                    </Flicking>
                                </div>
                            )}
                            <div
                                className={`app-promotion app-promotion--nail-techs ${isMobile && 'app-promotion--nail-techs--mobile'}`}
                            >
                                <div
                                    className={`app-promotion__box app-promotion__box--nail-techs`}
                                >
                                    <p
                                        className={`app-promotion__text ${isMobile && 'app-promotion__text--mobile'}`}
                                    >
                                        Blazzi was built with the nail tech in mind. Through Blazzi,
                                        you can advertise yourself apart from salons and confusing
                                        social media algorithms.
                                    </p>
                                    <button
                                        className={`app-promotion__button app-promotion__button--nail-techs`}
                                    >
                                        App coming soon
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="nail-techs__image-container">
                            <img
                                className={`nail-techs__image ${isMobile && 'nail-techs__image--mobile'}`}
                                src="/images/NailTechPromo.jpg"
                                alt="Nail techs"
                                onLoad={handleImageLoad}
                            />
                        </div>
                    </div>

                    <div className="app-screens">
                        <div
                            className={`app-screens__container ${isMobile ? 'app-screens__container--mobile' : null}`}
                        >
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--mobile' : null}`}
                            >
                                <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={3}>
                                    {!isMobile ? (
                                        <ScrollAnimation
                                            animateIn="slideInLeft"
                                            animateOnce={true}
                                            duration={3}
                                        >
                                            <div
                                                className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                            >
                                                <img
                                                    className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                                    src="/images/ProfileSetup.png"
                                                    alt="Blazzi app screen 1"
                                                    onLoad={handleImageLoad}
                                                />
                                                <p
                                                    className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                                >
                                                    Easily and quickly set up your business profile
                                                </p>
                                            </div>
                                        </ScrollAnimation>
                                    ) : (
                                        <div
                                            className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                        >
                                            <img
                                                className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                                src="/images/ProfileSetup.png"
                                                alt="Blazzi app screen 1"
                                                onLoad={handleImageLoad}
                                            />
                                            <p
                                                className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                            >
                                                Easily and quickly set up your business profile
                                            </p>
                                        </div>
                                    )}
                                </ScrollAnimation>
                            </div>
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--dark-mobile' : null}`}
                            >
                                <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={3}>
                                    <div
                                        className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                    >
                                        <img
                                            className={`app-screens__screen-image--large ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                            src="/images/NailTechProfile.png"
                                            alt="Blazzi app screen 2"
                                            onLoad={handleImageLoad}
                                        />
                                        <p
                                            className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                        >
                                            Manage your business from your dashboard
                                        </p>
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--mobile' : null}`}
                            >
                                <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={3}>
                                    {!isMobile ? (
                                        <ScrollAnimation
                                            animateIn="slideInRight"
                                            animateOnce={true}
                                            duration={3}
                                        >
                                            <div
                                                className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                            >
                                                <img
                                                    className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                                    src="/images/search-filters.png"
                                                    alt="Blazzi app screen 3"
                                                    onLoad={handleImageLoad}
                                                />
                                                <p
                                                    className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                                >
                                                    List standardized services so clients can find
                                                    you
                                                </p>
                                            </div>
                                        </ScrollAnimation>
                                    ) : (
                                        <div
                                            className={`app-screens__screen ${isMobile && 'app-screens__screen--mobile'}`}
                                        >
                                            <img
                                                className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                                src="/images/search-filters.png"
                                                alt="Blazzi app screen 3"
                                                onLoad={handleImageLoad}
                                            />
                                            <p
                                                className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                            >
                                                List standardized services so clients can find you
                                            </p>
                                        </div>
                                    )}
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
